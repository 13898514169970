<template>
  <main class="main news pt-5">
     <loading-overlay
      :active="filtering"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <page-slider
      v-if="hasData"
      :slides="pageData.acf.banner_group.slides"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="my-5 mt-5 pt-5">
      <section-title
        >Search results for "{{ $route.params.term }}"</section-title
      >
    </b-container>

    <b-container fluid="xl" class="px-0" v-if="filters">
       <form-category-market
        :listLength="searchList.length"
        :allowOrderBy="true"
        :allowPagination="true"
        @sortList="sortList"
        @perPage="perPage"
      />
    </b-container>

    <b-container fluid="xl" class="px-0">
      <articles-list-item v-for="(item, index) in searchList" :article="item">
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="bg-silver" />
        </b-container>
      </articles-list-item>

      <b-container
        fluid="xl"
        class="px-0 my-3 text-center"
        v-if="see_more && page < filters.total_pages"
      >
        <a @click="seeMore" class="px-0 my-5 text-uppercase more">
          <span>see more</span>
          <br />
          <img src="/images/icons/common/icon-down-arrow.svg" alt="" />
        </a>
      </b-container>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import ArticlesListItem from "../components/SearchListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    ArticlesListItem,
    Divider,
  },
  data() {
    return {
      filtering: false,
      fullPage: true,
      loader: "bars",
      currentPageSlug: "news",
      pageData: [],
      hasData: false,
      searchList: [],
      page: 1,
      filters: {},
      see_more: true,
      sort: 'asc',
      per_page: 5,
    };
  },
  created() {
    this.getSearch();
  },
  watch: {
    $route(to, from) {
      this.getSearch();
    },
  },
  methods: {
    getSearch() {
      this.filtering = true;
      axios
        // .get(this.backendUrl + "pages?slug=" + this.currentPageSlug)
        .get(
          `searchextended?s=${this.$route.params.term}&sort=${this.sort}&per_page=${this.per_page}`
          // ${this.backendUrl}news?news_category=${this.news_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}
        )
        .then((response) => {
          console.log("search", response);
          let length = response.data.length - 1;
          this.filters = response.data[length];
          console.log(this.filters);

          let object = response.data;
          object.pop();
          this.searchList = object;
          this.filtering = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortList(item) {
      console.log(item);
      this.sort = item;
      this.getSearch();
    },
    perPage(item) {
      this.page = 1;
      this.per_page = item;
      this.getSearch();
    },
    chooseFirstFilter(item) {
      this.news_category = item;
      this.getNews();
    },
    chooseSecondFilter(item) {
      this.market_category = item;
      this.getNews();
    },
    seeMore() {
      this.filtering = true;
      this.page++;

      axios
        .get(`searchextended?s=${this.$route.params.term}&page=${this.page}&per_page=${this.per_page}`)
        .then((response) => {
          console.log("seemore", response);

          let object = response.data;
          object.pop();

          if (object.length) {
            this.searchList.push(...object);
          } else {
            this.see_more = false;
          }

          this.filtering = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
